import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[disableAfterClick]'
})
/**
 * disable after click , prevent double click
 */
export class DisableAfterClickDirective implements OnInit, OnDestroy {
  /**
   * throttleTime
   */
  @Input()
  throttleTime = 800;
  /**
   * throttled Click
   */
  @Output()
  throttledClick = new EventEmitter();
  /**
   * click
   */
  private clicks = new Subject();
  /**
   * subscription
   */
  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe(e => this.emitThrottledClick(e));
  }
  /**
   * emit throttled click
   * @param e
   */
  emitThrottledClick(e) {
    this.throttledClick.emit(e);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * click event
   * @param e event
   */
  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
