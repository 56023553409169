import { Routes } from '@angular/router';
import { MainComponent } from 'app/layouts/main/main.component';

export const HOME_ROUTE: Routes = [
  {
    path: ':language',
    component: MainComponent,
    data: {
      authorities: [],
      pageTitle: 'LECIP Content Management Platform'
    }
  },
  {
    path: '',
    component: MainComponent,
    data: {
      authorities: [],
      pageTitle: 'LECIP Content Management Platform'
    }
  }
];
