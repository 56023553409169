import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, SortTypeEnum } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { IHash, OptionFilter } from 'app/model/entity/sort-filter-object';
import { Ticket } from 'app/model/entity/ticket';
import { ReservationPrices } from 'app/model/entity/ticket-editor/reservation-price';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { DialogCustomSortComponent } from '../dialog-custom-sort/dialog-custom-sort.component';
import { DialogExportReservationComponent } from '../dialog-export-reservation/dialog-export-reservation.component';

@Component({
  selector: 'price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit {
  @ViewChild('tableGrid', { static: true }) tableGrid: ElementRef;
  commonObject: Common; // common object
  public languageKey: string;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  helper = Helper;
  listReservationPrices: any[] = [];
  listFilterDisplay: Array<OptionFilter>;
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  isShowPopUpSortFilter: boolean;
  columnSortFiltering: string;
  headerColumns: any[] = [
    { headerName: 'ticket-editor.reservation.variable-price-category', property: 'dynamicPriceType' },
    { headerName: 'ticket-editor.reservation.price-explanation', property: 'reservationPriceDescription' }
  ]; // List header
  listSorted: any = [];
  listCurrentFilter: IHash = {};
  listFilterDisplayOrigin: Array<OptionFilter>;
  isFilter: boolean;
  dataFilterOrderConvert: Array<Ticket> = new Array<Ticket>();
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  positionPopUpSortFilter: any;
  constructor(
    public translateService: TranslateService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<DialogExportReservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.languageKey = this.commonObject?.setting?.language;
    if (this.data.iSMultiSection) {
      this.isSortFilter = true;
    }
    this.listReservationPrices = _.cloneDeep(this.convertListReservationPrices(this.data.listReservationPrices));
    this.dataFilterOrderConvert = this.convertListReservationPrices(_.cloneDeep(this.data.listReservationPrices));
    if (this.data?.iSMultiSection) {
      this.headerColumns.push(
        { headerName: 'ticket-editor.reservation.boarding-location', property: 'originStopName' },
        { headerName: 'ticket-editor.reservation.drop-off-location', property: 'destStopName' }
      );
    }
    if (this.checkTheExistenceOfThePriceLabel('priceAdult')) {
      this.headerColumns.push({ headerName: 'ticket-editor.reservation.adults', property: 'priceAdult' });
    }
    if (this.checkTheExistenceOfThePriceLabel('priceCustom1')) {
      this.headerColumns.push({ headerName: this.listReservationPrices[0]?.priceLabelCustom1, property: 'priceCustom1' });
    }
    if (this.checkTheExistenceOfThePriceLabel('priceCustom2')) {
      this.headerColumns.push({ headerName: this.listReservationPrices[0]?.priceLabelCustom2, property: 'priceCustom2' });
    }
    if (this.checkTheExistenceOfThePriceLabel('priceCustom3')) {
      this.headerColumns.push({ headerName: this.listReservationPrices[0]?.priceLabelCustom3, property: 'priceCustom3' });
    }
    this.headerColumns.forEach(e => {
      e.isFilterBy = '';
      e.isSortBy = '';
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 19) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 33) + '...';
    }
    return value;
  }

  /**
   * checkTheExistenceOfThePriceLabel
   * @param valueTableCol
   * @returns
   */
  checkTheExistenceOfThePriceLabel(valueTableCol: string) {
    if (!this.listReservationPrices || (this.listReservationPrices && !this.listReservationPrices.length)) {
      return false;
    }
    return this.data.listReservationPrices.some(e => !Helper.isEmpty(e[valueTableCol]));
  }

  /**
   * setMinWidthLabelHeader
   * @returns
   */
  setMinWidthLabelHeader(): string {
    const results = [
      this.checkTheExistenceOfThePriceLabel('priceAdult'),
      this.checkTheExistenceOfThePriceLabel('priceCustom1'),
      this.checkTheExistenceOfThePriceLabel('priceCustom2'),
      this.checkTheExistenceOfThePriceLabel('priceCustom3')
    ];
    const trueCount = results.filter(result => result == true).length;
    switch (trueCount) {
      case 1:
        return '150px';
      case 2:
      case 3:
      case 4:
        return '120px';
      default:
        break;
    }
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.listReservationPrices?.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
  }

  /**
   * showCustomSort
   */
  showCustomSort() {
    this.isShowPopUpSortFilter = false;

    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts.forEach(e => {
      if (!['priceCustom1', 'priceCustom2', 'priceCustom3'].includes(e.property)) {
        e.headerName = this.translateService.instant(e.headerName);
      }
    });
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns?.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }

        this.filterData();
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * filterData
   */
  filterData(ticketId?: any) {
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    if (this.listCurrentFilter && Object.keys(this.listCurrentFilter).length) {
      for (let filterTmp in this.listCurrentFilter) {
        let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
      }
    }
    this.listReservationPrices = listFilterTmp;
    this.listReservationPrices?.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   *showPopupSortFilter
   * @param title
   * @param event
   */
  showPopupSortFilter(property: string, event) {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.fetchFilterData(property);
    }
    this.positionPopUpSortFilter = this.isShowPopUpSortFilter
      ? this.popupDisplayDistanceSortAndFilter(`${'headerColumns'}${property}`)
      : { top: '0px', left: '0px' };
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    let isFiltered = false;
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    let objectTemp = {};
    let listCurrentFilterClone = _.cloneDeep(this.listCurrentFilter);
    for (let filterTmp in listCurrentFilterClone) {
      if (filterTmp == property) {
        isFiltered = true;
        objectTemp[property] = listCurrentFilterClone[filterTmp].filter(e => e.isChecked);
        continue;
      }
      let filter = listCurrentFilterClone[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let mapProperty = [...new Set(listFilterTmp?.map(e => e[property]))];
    let mapChecked = objectTemp[property]?.map(e => e.name);
    if (isFiltered) {
      mapProperty.forEach(e => {
        if (!mapChecked.includes(e)) {
          objectTemp[property].push({
            isChecked: false,
            name: e
          });
        }
      });
      let templ = _.cloneDeep(objectTemp[property]);
      let cnt = 0;
      for (let index = 0; index < templ?.length; index++) {
        if (!mapProperty.includes(templ[index].name)) {
          objectTemp[property].splice(index - cnt, 1);
          cnt++;
        }
      }
      delete listCurrentFilterClone[property];
      listCurrentFilterClone[property] = objectTemp[property];
    } else {
      objectTemp[property] = new Array<any>();
      mapProperty.forEach(e => {
        objectTemp[property].push({
          isChecked: true,
          name: e
        });
      });
      listCurrentFilterClone[property] = objectTemp[property];
    }
    this.listFilterDisplay = _.cloneDeep(listCurrentFilterClone[property]);
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    // get list memorize checked
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplay?.every(filter => filter.isChecked);
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    const index = this.headerColumns.findIndex(e => e.property == property && e.isFilterBy);
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.isFilter = false;
    delete this.listCurrentFilter[property];
    if (index != -1) {
      this.filterData();
    }
  }

  /**
   * filterOder
   * @param property
   * @param isFilterFirstTime
   * @returns
   */
  filterOder(property: string) {
    // do not filter all
    if (this.listFilterDisplay.every(e => !e.isChecked)) {
      this.listFilterDisplay.forEach(option => {
        option.isChecked = true;
      });
    }
    this.listCurrentFilter[property] = _.cloneDeep(this.listFilterDisplay);
    if (this.listFilterDisplay.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    const isEqual = JSON.stringify(this.listFilterDisplayOrigin) === JSON.stringify(this.listFilterDisplay);
    if (isEqual) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.filterData();
    this.listReservationPrices?.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * checkObjectType
   * @param input
   * @returns
   */
  checkObjectType(input) {
    return typeof input == 'object';
  }

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter?.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.data.listReservationPrices?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.data.listReservationPrices?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplay.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
  }

  /**
   * change checked
   * @param index index of option filter
   */
  checkOptionFilter(index: number) {
    this.listFilterDisplay[index].isChecked = !this.listFilterDisplay[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   *
   * @param listTicket
   * @returns
   */
  convertListReservationPrices(listTicket: any) {
    if (!listTicket || !listTicket.length) {
      return;
    }
    listTicket.forEach(e => {
      for (let property in e) {
        if (this.checkObjectType(e[property])) {
          e[property] = this.languageKey == 'en' ? e[property]?.en : e[property]?.ja;
        }
      }
    });

    return listTicket;
  }

  /**
   * popupDisplayDistanceSortAndFilter
   * @returns
   */
  popupDisplayDistanceSortAndFilter(headerId: string): { top: string; left: string } {
    const headerElement = document.getElementById(headerId);

    if (headerElement) {
      const rect = headerElement.getBoundingClientRect();
      const distanceFromTop = rect.top;
      const distanceFromLeft = rect.left;

      return {
        top: `${distanceFromTop + 26}px`,
        left: `${distanceFromLeft}px`
      };
    }

    return { top: '0px', left: '0px' };
  }
}

export interface DialogData {
  listReservationPrices: Array<ReservationPrices>;
  ticketSelected: Ticket;
  targetYearAndMonth: string;
  iSMultiSection: boolean;
}
