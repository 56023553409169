import { ComponentRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Constant, UserRoleEnum } from 'app/config/constants';
import { Privilege } from 'app/model/entity/privilege';
import { DataService } from 'app/service/data.service';
import { AppState } from 'app/store/app.state';
import { Subscription } from 'rxjs';
import { Helper } from '../layouts/helper';

@Directive({
  selector: '[checkPrivileges]'
})
export class CheckPrivilegesDirective implements OnInit, OnDestroy {
  private userRole: UserRoleEnum;
  private privileges: Privilege[];
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  componentRef: ComponentRef<any>;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly dataService: DataService,
    private readonly store: Store<AppState>
  ) {
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.privileges = componentState?.userInfoState?.stateOfComponent.privileges;
          this.userRole = componentState?.userInfoState?.stateOfComponent.user?.role.id;
        })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * check privileges
   */
  @Input()
  set checkPrivileges(moduleName: string) {
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == Constant.UPDATE_VIEW_BY_PRIVILEGES && data[1] == null) {
          this.updateView(moduleName);
        }
      })
    );
  }

  /**
   * update view
   *
   * @param moduleName
   */
  private updateView(moduleName: string): void {
    if (!this.privileges || !this.userRole) {
      return;
    }
    // clear view
    this.viewContainerRef.clear();
    // view permission check
    const isModuleAccessPermission = Helper.getPrivilegeViews(this.privileges, this.userRole).some(
      rolePrivilege => rolePrivilege.moduleName == moduleName
    );
    if (isModuleAccessPermission) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
