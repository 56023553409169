import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GatewaySharedModule } from 'app/shared/shared.module';
import { HOME_ROUTE } from './home.route';

@NgModule({
  imports: [GatewaySharedModule, RouterModule.forChild(HOME_ROUTE)]
})
export class GatewayHomeModule {}
