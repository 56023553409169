import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, TemplateModeEnum } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DisplaySetting, DisplaySettingSchedule } from 'app/model/entity/schedule/display-setting-schedule';
import { Template } from 'app/model/entity/template';
import { DialogService } from 'app/service/dialog.service';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { ScheduleRegistrationService } from 'app/service/schedule-registration.service';
import { TemplateService } from 'app/service/template.service';
import _ from 'lodash';
@Component({
  selector: 'dialog-template-setting-schedule',
  templateUrl: './dialog-template-setting-schedule.component.html',
  styleUrls: ['./dialog-template-setting-schedule.component.scss']
})
export class DialogTemplateSettingScheduleComponent implements OnInit {
  /**
   * max display setting
   */
  public readonly MAX_DISPLAY_SETTING = 20;
  /**
   * displaySettingSchedule
   */
  public displaySettingSchedule: DisplaySettingSchedule;
  /**
   * displaySettings
   */
  public displaySettings: Array<DisplaySetting>;
  /**
   * templates
   */
  templates: Array<Template>;

  constructor(
    private dialogRef: MatDialogRef<DialogTemplateSettingScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private templateService: TemplateService,
    private scheduleMergeService: ScheduleMergeService,
    private scheduleRegistrationService: ScheduleRegistrationService,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.getAllTemplate();
    this.getAllDisplay();
  }

  /**
   * add new row
   * @returns
   */
  public addRow(): void {
    if (this.displaySettings.length == this.MAX_DISPLAY_SETTING) {
      return;
    }
    this.displaySettings.push(new DisplaySetting(this.displaySettings.length));
  }

  /**
   * get templates by template group mode
   */
  private getAllTemplate(): void {
    this.templateService.getTemplatesByGroupMode(TemplateModeEnum.TIMETABLE).subscribe(data => {
      this.templates = Helper.convertDataTemplatesBackward(data);
      this.templates.sort((template1, template2) => {
        return <number>template1.templateGroupId - <number>template2.templateGroupId || <number>template1.id - <number>template2.id;
      });
    });
  }

  /**
   * get all display
   */
  private getAllDisplay(): void {
    this.data.isScheduleRegistration
      ? this.handleGetDisplay(this.scheduleRegistrationService)
      : this.handleGetDisplay(this.scheduleMergeService);
  }

  /**
   * save
   */
  public save(): void {
    const templateIds = _.cloneDeep(this.displaySettings).map(element => element.templateId);
    let lastIndex = _.findLastIndex(templateIds, item => item != null);
    if (lastIndex != -1) {
      this.displaySettingSchedule.templateIds =
        lastIndex >= Constant.DEFAULT_DISPLAY_TEMPLATE
          ? templateIds.splice(0, lastIndex + 1)
          : templateIds.splice(0, Constant.DEFAULT_DISPLAY_TEMPLATE);
      this.data.isScheduleRegistration ? this.handleSave(this.scheduleRegistrationService) : this.handleSave(this.scheduleMergeService);
    } else {
      this.displaySettingSchedule.templateIds = templateIds.splice(0, Constant.DEFAULT_DISPLAY_TEMPLATE);
      this.data.isScheduleRegistration ? this.handleSave(this.scheduleRegistrationService) : this.handleSave(this.scheduleMergeService);
    }
  }

  /**
   * handle save
   * @param service
   */
  private handleSave(service: any): void {
    this.displaySettingSchedule.routeId = this.data.routeId;
    service.saveDisplaySettingSchedule(Helper.convertDataSettingScheduleBackward(this.displaySettingSchedule)).subscribe(
      data => {
        this.dialogRef.close([...new Set(JSON.parse(data['templateIds']).filter(element => element))]);
      },
      error => this.handleError(error)
    );
  }

  /**
   * handle get display
   * @param service
   */
  private handleGetDisplay(service: any): void {
    service.getDisplaySettingSchedule(this.data.routeId).subscribe(data => {
      if (!data) {
        this.displaySettingSchedule = new DisplaySettingSchedule();
        this.displaySettings = Array(Constant.DEFAULT_DISPLAY_TEMPLATE)
          .fill(null)
          .map((item, index) => new DisplaySetting(index));
        console.log(this.displaySettings);
      } else {
        this.displaySettingSchedule = Helper.convertDataSettingSchedule(data);
        this.displaySettings = this.displaySettingSchedule.templateIds.map((item, index) => new DisplaySetting(index, item));
      }
    });
  }

  /**
   * handle error
   * @param error
   */
  private handleError(error: any) {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('schedule-merge.msg.title-error'),
        text:
          error.error?.detail == Constant.DUPLICATE_TIMETABLE_ID
            ? this.translateService.instant('schedule-merge.msg.duplicate-timetable-id')
            : this.translateService.instant('schedule-merge.msg.common-error')
      }
    });
  }

  /**
   * close
   */
  public close(): void {
    this.dialogRef.close(undefined);
  }
}
export interface DialogData {
  isScheduleRegistration: boolean;
  routeId: any;
}
