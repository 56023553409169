import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { Constant } from '../config/constants';
import { FileMedia } from '../model/entity/file-media';

@Directive({
  selector: '[mediaDrag]'
})
export class MediaDragDirective {
  @Output('files') files: EventEmitter<any[]> = new EventEmitter();
  constructor() {}
  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    if (evt.dataTransfer.getData(Constant.MEDIA_VALUE) != '') {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    let fileMedia: FileMedia;
    if (evt.dataTransfer.files.length == 0) {
      return;
    }
    const file = evt.dataTransfer.files[0];
    const url = window.URL.createObjectURL(file);
    let fileType = file.type.split('/');
    let type = fileType.length != 2 ? '' : fileType[1] == 'jpeg' ? 'jpg' : fileType[1];
    fileMedia = { file, url, type };
    this.files.emit([evt.dataTransfer.files, fileMedia]);
  }
}
