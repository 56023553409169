import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[blockDrop]'
})
export class BlockDropInInputTextDirective {
  constructor() {}

  @HostListener('drop', ['$event'])
  preventDrop(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  dragOver(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer.effectAllowed = 'none';
    event.dataTransfer.dropEffect = 'none';
    return;
  }
}
