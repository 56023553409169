import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dialogDragDrop]'
})
export class DialogDragDropDirective {
  isMouseDown: boolean = false;
  dragPoint: any;

  constructor(private modalDialogElement: ElementRef, private renderer: Renderer2) {}

  /**
   * mouse down subscriber
   * @param e
   */
  @HostListener('mousedown', ['$event'])
  mouseDown(e) {
    if (e.target.id != 'modal-dialog-header') {
      return;
    }
    this.isMouseDown = true;
    this.dragPoint = e;
  }

  /**
   * mouse up subscriber
   * @param e
   */
  @HostListener('mouseup', ['$event'])
  mouseUp(e) {
    if (!this.isMouseDown) {
      return;
    }
    this.isMouseDown = false;
    this.dragPoint = null;
  }

  /**
   * mouse move subscriber
   * @param e
   */
  @HostListener('mousemove', ['$event'])
  mouseMove(e) {
    if (!this.isMouseDown) {
      return;
    }
    let distanceX = e.x - this.dragPoint.x;
    let distanceY = e.y - this.dragPoint.y;
    let left = parseInt(this.modalDialogElement.nativeElement.style.left);
    let top = parseInt(this.modalDialogElement.nativeElement.style.top);
    this.renderer.setStyle(this.modalDialogElement.nativeElement, 'left', `${left + distanceX}px`);
    this.renderer.setStyle(this.modalDialogElement.nativeElement, 'top', `${top + distanceY}px`);
    this.dragPoint = e;
  }
}
