import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[customFocusout]'
})
export class CustomFocusoutDirective {
  /**
   * idElementList
   */
  @Input()
  idElementList = [];
  /**
   * focusOut
   */
  @Output()
  focusOut = new EventEmitter();

  constructor() {}
  /**
   * blur event
   * @param e event
   */
  @HostListener('blur', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.idElementList?.some(id => id == event.relatedTarget?.id)) {
      this.focusOut.emit(event);
    }
  }

  @HostListener('focusout', ['$event'])
  focusOutElement(event) {
    this.focusOut.emit(event);
  }
}
